import { useInView } from "framer-motion";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

type Props = {
  tag?: React.ElementType;
  once?: boolean;
  onIntersection: (inView: boolean) => void;
} & PropsWithChildren;

export default function IntersectionWrapper(props: Props) {
  const DynamicTag = `${props.tag || "div"}` as React.ElementType;

  const [viewed, setViewed] = useState(false);
  const refDynamicTag = useRef<HTMLElement | null>(null);

  const inView = useInView(refDynamicTag);

  useEffect(() => {
    if (!props.once || !viewed) {
      props.onIntersection(inView);
      if (!viewed && inView) {
        setViewed(true);
      }
    }
  }, [inView, props, viewed]);

  return <DynamicTag ref={refDynamicTag}>{props.children}</DynamicTag>;
}
